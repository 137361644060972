import React, { useEffect, useState } from "react";
import NavBarComponent from "../navbar-component/navBarComponent";
import './styles.css'
import { useNavigate } from 'react-router-dom';
import FeatureContainer from "../feature-container/featureContainer";
import PartnerComponent from "../partner-component/partnerComponent";
import ScreenFooter from "../screen-footer/screenFooter";

const FeaturesList = props => {
    let navigate = useNavigate();

    return (<div>
        <NavBarComponent navigate={navigate} />
        <header className="contact-hero">
            <div className="content">
                <div className="contact-label-container">
                    <div className="contact-label">Features</div>
                    <div className="h-line-white" />
                </div>
                <div className="have-question-label">Expect only the best solution from us.</div>
                <div className="have-question-sub-text">GoMoBites provides a variety of solutions for both the restaurants and their customers.</div>
            </div>
        </header>
        <div className="feature-heading-container">
            <div className="need-help-label">GoMoBites Features; <span className="need-help-label-sub">from digitalizing menu card to customer placing order(s), below is list of features you will get as part of our solutions for your day to day tasks.</span></div>
        </div>
        <div className="container">
            <div className="row">
                <FeatureContainer
                    image={require('../../../assets/ic_feature_location.png')}
                    label='Location Based Menu'
                    description='Create and manage restaurant menu for multiple location for restaurant.' />
                <FeatureContainer
                    image={require('../../../assets/ic_feature_order.png')}
                    label='Order Management'
                    description='Manage incoming orders filtered by location. Option for accepting and cancelling order.' />
                <FeatureContainer
                    image={require('../../../assets/ic_feature_employee.png')}
                    label='Employee Access'
                    description='Provide console access to added employee for seamless operation.' />
                <FeatureContainer
                    image={require('../../../assets/ic_feature_reservation.png')}
                    label='Reservation'
                    description='Manage your customers table and room reservation.' />
                <FeatureContainer
                    image={require('../../../assets/ic_feature_qr_code.png')}
                    label='QR Base Menu'
                    description='No app menu for your customers. Generate and place QR code on table and let your customer to view menu and place the order.' />
                <FeatureContainer
                    image={require('../../../assets/ic_feature_payment.png')}
                    label='**No contact payment'
                    description='We have partnered with Stripe for contact-less payment. The customer when ordering will be allowed to pay at the same time.' />
            </div>
        </div>
        <PartnerComponent />
        <ScreenFooter navigate={navigate} />
    </div>)
}

export default FeaturesList